import React from "react";
import SEO from "../../../../components/seo";
import SharePriceDataWidget from "../../../../components/SharePriceDataWidget";

function PriceVerticalWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget />
    </>
  );
}

export default PriceVerticalWidget;
